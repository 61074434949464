import styled from 'styled-components'

export default styled.div`
  width: 100%;

  .total-invalid {
    color: #f00
  }

  .total-valid {
    color: #00bc00
  }

  .values-file-container {
    padding: 10px 0;
    border-top: 1px solid #eeeeee;

    .text-container {
      font-size: 1.5rem;

      .file-name {
        font-weight: bold;
      }
    }

    .table-container {
      margin-top: 10px;
      margin-bottom: 10px;

      table {
        border-collapse: collapse;
        width: 100%;
      }

      td, th {
        border: 1px solid #eeeeee;
        padding: 6px;
      }

      tr:nth-child(even) {
        background-color: #eeeeee;
      }

      .correct-field {
        font-weight: bold;
        color: #00bc00;
      }

      .invalid-field {
        font-weight: bold;
        color: #f00
      }
    }

  }
`
