import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StopIcon from '@material-ui/icons/Stop'

import { Pagination } from 'ui/components'

import { getConsults } from 'services/apiRpg'

import Container from './styles'

const ListConsults = ({ onSelectItem, lastUpdatedDate }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [loading, setLoading] = useState(false)
  const [tokenSelected, setTokenSelected] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [consultList, setConsultList] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const fetchConsults = async (options) => {
    let res = null
    try {
      res = await getConsults(
        apiKey,
        userId,
        options,
      )
    } catch (err) {
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter lista de consultas'
      toast.error(messageError)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalRequests(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setConsultList(res.data)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchConsults({ page: currentPage })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (_.isEmpty(lastUpdatedDate)) return

    setLoading(true)
    fetchConsults({ page: currentPage })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedDate])

  const handleGetConsult = async (token) => {
    setTokenSelected(token)
    onSelectItem(token)
    setDrawerOpen(false)
  }

  const handleChangePage = async (_event, page) => {
    setLoading(true)
    fetchConsults({ page: page })
  }

  const renderList = (list) => {
    const items = _.map(list, (item, i) => (
      <ListItem
        key={i.toString()}
        button={true}
        selected={item.token === tokenSelected}
        onClick={() => handleGetConsult(item.token)}
      >
        <ListItemIcon>
          { item.concluded ? <CheckCircleIcon /> : <StopIcon /> }
        </ListItemIcon>
        <ListItemText
          primary={<span className="item-text">{item.first_file_name}</span>}
          secondary={<span className="item-text">{item.created_at}</span>}
        />
      </ListItem>
    ))

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 480 }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
          <span style={{ fontSize: 22, margin: 10 }}>Consultas</span>
          <span style={{ fontSize: 16 }}>{`total consultas: ${totalRequests}`}</span>
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
        />

        {loading
        ? <CircularProgress />
        : (
          <List component="nav">
            {items}
          </List>
        )}
      </div>
    )
  }

  return (
    <Container>
      <div className="button-open-drawer">
        <Button variant="contained" onClick={() => setDrawerOpen(true)}>
          Consultas
        </Button>
      </div>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => {}}
      >
        {renderList(consultList)}
      </SwipeableDrawer>
    </Container>
  )
}

export default ListConsults
