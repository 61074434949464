import React from 'react'
import _ from 'lodash'

import { booleanToText, formatToCurrency } from 'utils/functions'

import Container from './styles'

const IrReceiptValues = ({ filesData }) => {
  if (_.isEmpty(filesData)) return <span>IR: Nenhum arquivo de Recibo</span>

  return (
    <Container>
      <h2><ul><li>Recibo</li></ul></h2>

      {_.map(filesData, (fileData, fileIndex) => {
        const receiptData = fileData.receipt || {}

        return (
          <div className="values-file-container" key={fileIndex.toString()}>
            <div className="text-container">
              <p>
                <span>{`Arquivo #${fileIndex + 1}:`}</span>
                {' '}
                <span className="file-name">{fileData.name}</span>
              </p>

              <br/>
              <br/>
              <p>
                <strong>Recibo Válido: </strong>
                <strong className={!receiptData.receipt_valid ? 'total-invalid' : 'total-valid' }>{booleanToText(receiptData.receipt_valid)}</strong>
              </p>
            </div>

            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Valores do Recibo</th>
                    <th>Valores iguais aos da declaração</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Tipo da declaração:</strong>
                      {' '}
                      <span>{receiptData.type_receipt}</span>
                    </td>
                    <td>
                      <span className={!receiptData.type_receipt_valid ? 'invalid-field' : 'correct-field' }>
                        {booleanToText(receiptData.type_receipt_valid)}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>CPF:</strong>
                      {' '}
                      <span>{receiptData.cpf}</span>
                    </td>
                    <td>
                      <span className={!receiptData.cpf_valid ? 'invalid-field' : 'correct-field' }>
                        {booleanToText(receiptData.cpf_valid)}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Nome:</strong>
                      {' '}
                      <span>{receiptData.name}</span>
                    </td>
                    <td>
                      <span className={!receiptData.name_valid ? 'invalid-field' : 'correct-field' }>
                        {booleanToText(receiptData.name_valid)}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Ano do calendário:</strong>
                      {' '}
                      <span>{receiptData.calendar_year}</span>
                    </td>
                    <td>
                      <span className={!receiptData.calendar_year_valid ? 'invalid-field' : 'correct-field' }>
                        {booleanToText(receiptData.calendar_year_valid)}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Ano do exercício:</strong>
                      {' '}
                      <span>{receiptData.exercise_year}</span>
                    </td>
                    <td>
                      <span className={ !receiptData.exercise_year_valid ? 'invalid-field' : 'correct-field' }>
                        {booleanToText(receiptData.exercise_year_valid)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            { receiptData.sale_quotation_usd && (
              <div className="values-data-container">
                <br/>
                <br/>
                <p>
                  <span>PTAX dia anterior:</span>
                  {' '}
                  <span className="total-correct">
                    { formatToCurrency(receiptData.sale_quotation_usd, { withSymbol: true, minimumFractionDigits: 6 }) }
                  </span>
                </p>
                <br/>
                <p>
                  <span>Rendimentos tributaveis:</span>
                  {' '}
                  <span className="total-correct">
                    { formatToCurrency(receiptData.taxable_income_deducted * 2, { withSymbol: true }) } / { formatToCurrency(receiptData.taxable_income_deducted, { withSymbol: true }) } / { formatToCurrency(receiptData.taxable_income_deducted_usd, { withSymbol: true, currency: 'USD' }) }
                  </span>
                </p>
              </div>
            )}
            <br/>
            <br/>
            <hr/>
          </div>
        )
      })}
    </Container>
  )
}

export default IrReceiptValues
