import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'

import { reqS3PresignerUrl } from 'services/apiFilesDownloader'
import { FILE_TYPE } from 'utils/constants'

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const extractOnlyNumbers = (cpf) => {
  return cpf.replace(/[^\d]/g, '')
}

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const s3PresignerUrlOpen = async (docUrl) => {
  if (!docUrl) return

  try {
    const response = await reqS3PresignerUrl(docUrl)

    window.open(response.data.url, '_blank')
  } catch (err) {
    Sentry.captureException(err)

    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }
  }
}

export const formatToCurrency = (val, { withSymbol = false, currency = 'BRL', minimumFractionDigits = 2 } = {}) => {
  if (!Number.isFinite(val)) return ''

  const options = {
    minimumFractionDigits: minimumFractionDigits,
  }
  if (withSymbol === true) {
    options.style = 'currency'
    options.currency = currency
  }
  return val.toLocaleString('pt-br', options)
}

export const getFileNameExtension = (fileName) => fileName.split('.').pop()

export const calculeTotal = (filesType, filesData, { filterChecked, percentage = 100 } = {}) => {
  const assetsField = filesType === FILE_TYPE.IR ? 'assets_declaration' : 'assets_details'
  const valueField = filesType === FILE_TYPE.IR ? 'current_year_asset_value' : 'value'

  const totals = _.map(filesData, fileData => {
    const totalByFile = _.chain(fileData[assetsField])
                          .filter((val) => val.checked === true )
                          .map(declaration => declaration[valueField])
                          .sum()
                          .value()

    return totalByFile
  })

  return Number.parseFloat((_.sum(totals) / 100 * percentage).toFixed(2))
}

export const addCheckInIrDeclarations = (dataFiles) => {
  const newDataFiles = _.cloneDeep(dataFiles)

  // O trecho de código abaixo foi removido, pois esse tratamento já é feito no backend.
  /*
  _.each(newDataFiles, dataFile => {
    _.each(dataFile.assets_declaration, declaration => {
      declaration.checked = true
      if (declaration.country_code !== '105') declaration.checked = false
      else if (declaration.classification !== 'LÍQUIDO') declaration.checked = false
    })
  })
  */
  return newDataFiles
}

export const addCheckInDcbeAssets = (dataFiles) => {
  const newDataFiles = _.cloneDeep(dataFiles)

  _.each(newDataFiles, dataFile => {
    _.each(dataFile.asset_details, asset => {
      asset.checked = true
    })
  })

  return newDataFiles
}

export const booleanToText = (bool) => bool === true ? 'SIM' : 'NÃO'
