import React from 'react'
import _ from 'lodash'

import {
  Checkbox,
} from '@material-ui/core'

import { booleanToText, calculeTotal, formatToCurrency } from 'utils/functions'
import { FILE_TYPE } from 'utils/constants'

import Container from './styles'

// eslint-disable-next-line
const isDisableCheck = (declaration) => {
  if (declaration.country_code !== '105') return false
  if (!['LÍQUIDO', 'ILÍQUIDO'].includes(declaration.classification)) return false

  return true
}

const IrValues = ({ filesData, irDataFilesReceipt, valueMatched, validTotal, equityEvolution, onDeclarationCheck, selectedItem }) => {
  if (_.isEmpty(filesData)) return(
    <Container>
      <div className="values-data-container">
        <span>IR: Nenhum arquivo de Declaração</span>
        <br/>
        <br/>
        <hr/>
      </div>
    </Container>
  )

  const renderTotals = () => {
    let totals = _.filter(filesData, 'summary')

    let receipt_taxable_income_deducted_usd = 0.0
    if (irDataFilesReceipt[0] && irDataFilesReceipt[0].receipt) {
      receipt_taxable_income_deducted_usd = irDataFilesReceipt[0].receipt.taxable_income_deducted_usd || 0.0
    }

    let taxable_income = _.filter(totals, function(total){ return _.has(total.summary, 'taxable_income'); })[0];
    let other_informations = _.filter(totals, function(total){ return _.has(total.summary, 'other_informations'); })[0];

    let percentage = taxable_income.summary.taxable_income.percent_taxable_income

    let total_deducted_brl = taxable_income.summary.taxable_income.total_deducted
    let exempt_income_deducted_brl = other_informations.summary.other_informations.exempt_income_deducted
    let income_subject_to_exclusive_taxation_deducted_brl = other_informations.summary.other_informations.income_subject_to_exclusive_taxation_deducted
    let total_brl = total_deducted_brl + exempt_income_deducted_brl + income_subject_to_exclusive_taxation_deducted_brl + calculeTotal(FILE_TYPE.IR, filesData)

    let total_deducted_usd = taxable_income.summary.taxable_income.total_deducted / taxable_income.summary.taxable_income.sale_quotation_usd
    let exempt_income_deducted_usd = other_informations.summary.other_informations.exempt_income_deducted / taxable_income.summary.taxable_income.sale_quotation_usd
    let income_subject_to_exclusive_taxation_deducted_usd = other_informations.summary.other_informations.income_subject_to_exclusive_taxation_deducted / taxable_income.summary.taxable_income.sale_quotation_usd
    
    let total_usd = (
      // total_deducted_usd +
      receipt_taxable_income_deducted_usd +
      exempt_income_deducted_usd +
      income_subject_to_exclusive_taxation_deducted_usd
    ) + (calculeTotal(FILE_TYPE.IR, filesData) / taxable_income.summary.taxable_income.sale_quotation_usd)
    
    return (
      <div className="values-data-container" style={{ marginTop: 25 }}>
        {false && <p>
          <span>PTAX dia anterior:</span>
          {' '}
          <span className="total-correct">
            { formatToCurrency(taxable_income.summary.taxable_income.sale_quotation_usd, { withSymbol: true, minimumFractionDigits: 6 }) }
          </span>
        </p>}
        <p style={{ marginTop: 10 }}>
          <span>Rendimentos isentos e não tributáveis:</span>
          {' '}
          <span className={!other_informations.summary.other_informations.exempt_income_valid ? 'total-correct total-invalid' : 'total-correct'}>
            { formatToCurrency(exempt_income_deducted_brl * 2, { withSymbol: true }) } / { formatToCurrency(exempt_income_deducted_brl, { withSymbol: true }) } / { formatToCurrency(exempt_income_deducted_usd, { withSymbol: true, currency: 'USD' }) }
          </span>
        </p>
        <p style={{ marginTop: 10 }}>
          <span>Rendimentos suj. a trib. exclusiva/definitiva:</span>
          {' '}
          <span className={!other_informations.summary.other_informations.income_subject_to_exclusive_taxation_valid ? 'total-correct total-invalid' : 'total-correct'}>
            { formatToCurrency(income_subject_to_exclusive_taxation_deducted_brl * 2, { withSymbol: true }) } / { formatToCurrency(income_subject_to_exclusive_taxation_deducted_brl, { withSymbol: true }) } / { formatToCurrency(income_subject_to_exclusive_taxation_deducted_usd, { withSymbol: true, currency: 'USD' }) }
          </span>
        </p>
        <p style={{ marginTop: 10 }}>
          <span>Soma de Ativos líquidos (100%):</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.IR, filesData),
              { withSymbol: true },
            )} / {
              formatToCurrency(
                calculeTotal(FILE_TYPE.IR, filesData) / taxable_income.summary.taxable_income.sale_quotation_usd,
                { withSymbol: true, currency: 'USD' },
            )}
          </span>
        </p>
        <p style={{ marginTop: 10 }} className={total_usd < 18000 ? 'invalid-color' : 'valid-color'}>
          <span>Resultado:</span>
          {' '}
          <span className="total-correct">
            { formatToCurrency(total_usd, { withSymbol: true, currency: 'USD' }) }
          </span>
        </p>
        { total_usd < 18000 && <p style={{ marginTop: 10 }} className='warning'>
          <span className="total-correct">
            Limite menor que U$$ 18.000,00
          </span>
        </p>}
        <br/>
        <br/>
      </div>
    )
  }

  const renderDependents = (data) => {
    const dependents = filesData[_.findKey(filesData, 'dependents')]

    return (
      <div className="values-files-container">
        <div className="values-file-container">
          <div className="text-container">
            <p>
              Possui dependentes: 
              <b className="file-name"> {booleanToText(!_.isEmpty(dependents) && !_.isEmpty(dependents.dependents))}</b>
            </p>
            <br/>
            <br/>
          </div>

          { (!_.isEmpty(dependents) && !_.isEmpty(dependents.dependents)) &&
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data Nascimento</th>
                    <th>CPF</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(dependents.dependents, (dependent, fileIndex) => {
                      return(
                        <tr key={fileIndex.toString()}>
                          <td><span>{dependent.name}</span></td>
                          <td><span>{dependent.birthdate}</span></td>
                          <td><span>{dependent.cpf}</span></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>
    )
  }

  const renderTaxpayerIdentification = (data) => {
    const taxpayerIdentification = _.get(data, 'taxpayer_identification')
    if (_.isEmpty(taxpayerIdentification)) return null

    return (
      <div className="table-container">
        <h3>Identificação</h3>

        <table>
          <tbody>
            <tr>
              <td><strong>CPF:</strong></td>
              <td><span>{taxpayerIdentification.cpf}</span></td>
            </tr>
            <tr>
              <td><strong>Nome:</strong></td>
              <td><span>{taxpayerIdentification.name}</span></td>
            </tr>
            <tr>
              <td><strong>Tipo de Declaração:</strong></td>
              <td><span>{taxpayerIdentification.type_ir}</span></td>
            </tr>
            <tr>
              <td><strong>Natureza da Ocupação:</strong></td>
              <td><span>{taxpayerIdentification.occupation_nature}</span></td>
            </tr>
            <tr>
              <td><strong>Ano Calendário:</strong></td>
              <td><span>{taxpayerIdentification.calendar_year}</span></td>
            </tr>
            <tr>
              <td><strong>Ano Exercício:</strong></td>
              <td><span>{taxpayerIdentification.exercise_year}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const renderAssetDeclaration = (data, fileIndex) => {
    const assetsDeclaration = _.get(data, 'assets_declaration')
    if (_.isEmpty(assetsDeclaration)) return null

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Calcular</th>
              { assetsDeclaration[0].asset_group_code &&
                <th>Grupo</th>
              }
              <th>Código</th>
              <th>Código do País</th>
              <th>Descrição</th>
              <th>Classificação</th>
              <th>Valor Ano Anterior</th>
              <th>Valor Atual</th>
            </tr>
          </thead>
          <tbody>
            {_.map(assetsDeclaration, (declaration, j) => (
              <tr key={j.toString()}>
                <td>
                  <Checkbox
                    checked={declaration.checked}
                    color="primary"
                    disabled={selectedItem}
                    onChange={(e) => onDeclarationCheck(e, fileIndex, j)}
                  />
                </td>
                { declaration.asset_group_code &&
                  <td>{declaration.asset_group_code}</td>
                }
                <td>{declaration.asset_code}</td>
                <td>{`${declaration.country_code} - ${declaration.country_name}`}</td>
                <td>{declaration.asset_description}</td>
                <td>{declaration.classification}</td>
                <td>{formatToCurrency(declaration.before_year_asset_value)}</td>
                <td>{formatToCurrency(declaration.current_year_asset_value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderFiles = () => {
    return (
      <div className="values-files-container">
        {_.map(_.filter(filesData, 'assets_declaration'), (data, fileIndex) => (
          <div className="values-file-container" key={fileIndex.toString()}>
            <div className="text-container">
              <p>
                <span>{`Arquivo #${fileIndex + 1}:`}</span>
                {' '}
                <span className="file-name">{data.name}</span>
              </p>
              <br/>
              <br/>
              <p>
                {/* <span>Arquivo correto:</span> */}
                <span>Qtde de códigos de ativos confere com a qtde de valores:</span>
                {' '}
                <span className={ !data.amount_asset_code_equal_amount_asset_values ? 'file-incorrect total-invalid' : 'file-correct' }>
                  {booleanToText(data.amount_asset_code_equal_amount_asset_values)}
                </span>
              </p>
            </div>

            {renderTaxpayerIdentification(data)}
            {renderAssetDeclaration(data, fileIndex)}
          </div>
        ))}

      <p>
        <span>Valor total confere com a soma de todos os ativos:</span>
        {' '} 
        <span className={ !valueMatched ? 'matched-incorrect total-invalid' : 'matched-correct' }>
          {booleanToText(valueMatched)}
        </span>
      </p>
      </div>
    )
  }

  return (
    <Container>
      <h2><ul><li>Declaração</li></ul></h2>

      <div style={{ width: '100%' }}>
        {renderTotals()}
        {renderDependents()}
        {renderFiles()}
      </div>

      <br/>
      <br/>
      <hr/>
    </Container>
  )
}

export default IrValues
