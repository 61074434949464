import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import { Document, Page, pdfjs } from 'react-pdf'
import { saveAs } from 'file-saver'

import {
  Button,
  CircularProgress,
  Fab,
  Input,
} from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import { getConsultResponse, postFile, postGenerateCsv, postGeneratePdf, postConclude } from 'services/apiRpg'
import {
  addCheckInDcbeAssets,
  addCheckInIrDeclarations,
  calculeTotal,
  getFileNameExtension,
} from 'utils/functions'
import { FILE_TYPE } from 'utils/constants'

import IrValues from './IrValues'
import IrReceiptValues from './IrReceiptValues'
import DcbeValues from './DcbeValues'
import DcbeReceiptValues from './DcbeReceiptValues'
import ListConsults from './ListConsults'
import Container from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Home = () => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const tokenDocument = useRef()
  const irInputFile = useRef()
  const dcbeInputFile = useRef()

  const [loading, setLoading] = useState(false)
  const [getToken, setToken] = useState()
  const [selectedItem, setSelectedItem] = useState(false)
  const [imgsBase64, setImgsBase64] = useState([])
  const [sendedFiles, setSendedFiles] = useState(false)
  const [lastSendDate, setLastSendDate] = useState(null)

  const [irDataFilesDeclaration, setIrDataFilesDeclaration] = useState([])
  const [irAssets, setIrAssets] = useState([])
  const [irDataFilesReceipt, setIrDataFilesReceipt] = useState([])
  const [irEquityEvolution, setIrEquityEvolution] = useState(null)
  const [irValidTotal, setIrValidTotal] = useState(null)
  const [valueMatched, setValueMatched] = useState(false)

  const [dcbeDataFilesDeclaration, setDcbeDataFilesDeclaration] = useState([])
  const [dbceAssets, setDbceAssets] = useState([])
  const [dcbeDataFilesReceipt, setDcbeDataFilesReceipt] = useState([])
  const [dcbeGroupedValues, setDcbeGroupedValues] = useState(null)
  const [dcbeValidTotal, setDcbeValidTotal] = useState(null)
  const [dcbeResponse, setDcbeResponse] = useState(null)

  const resetStates = (options = {}) => {
    const {
      resetToken = true,
      resetImgsBase64 = true,
      resetSelectedItem = true,
    } = options

    if (resetToken) tokenDocument.current = null
    if (resetImgsBase64) setImgsBase64([])
    if (resetSelectedItem) setSelectedItem(false)
    irInputFile.current.value = null
    dcbeInputFile.current.value = null
    setLastSendDate(null)
    setLoading(false)
    setSendedFiles(false)
    setIrDataFilesDeclaration([])
    setIrDataFilesReceipt([])
    setIrEquityEvolution(null)
    setValueMatched(false)
    setIrValidTotal(null)
    setDcbeDataFilesDeclaration([])
    setDcbeDataFilesReceipt([])
    setDcbeGroupedValues(null)
    setDcbeValidTotal(null)
  }

  const handleFile = async (event, fileType) => {
    if (sendedFiles === true) {
      setImgsBase64([])
    }

    const { files } = event.target
    const newFiles = []

    _.each(files, file => {
      // TODO: check if file is image or pdf
      // TODO: validar tamanho dos arquivos
      const reader = new FileReader()

      reader.onloadend = () => {
        newFiles.push({
          name: file.name,
          base64: reader.result,
          fileType: fileType,
        })
        if (_.size(newFiles) === _.size(files)) {
          const filesUniq = _.differenceBy(newFiles, imgsBase64, 'base64')
          if (!_.isEmpty(filesUniq)) setImgsBase64(prevVal => ([ ...prevVal, ...filesUniq ]))
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const handleDocumentPdfLoadSuccess = ({ numPages }, fileIndex) => {
    const newImgsBase64 = _.cloneDeep(imgsBase64)
    _.set(
      newImgsBase64,
      `${fileIndex}.pdfNumPages`,
      numPages,
    )

    setImgsBase64(newImgsBase64)
  }

  const handleDocumentPdfLoadError = (err) => {
    console.error(err.message)
    Sentry.captureException(err)
  }

  const handleFileDelete = (fileIndex) => {
    const newFiles = _.cloneDeep(imgsBase64)
    newFiles.splice(fileIndex, 1)
    setImgsBase64(newFiles)
  }

  const getFilesResult = (token, selectedI = false) => {
    setLoading(true)

    let res = null

    const intervalId = setInterval(async () => {
      try {
        res = await getConsultResponse(
          apiKey,
          userId,
          token,
        )
      } catch (err) {
        Sentry.captureException(err)
        setLoading(false)

        const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter dados'
        toast.error(messageError)
        return
      }

      if (res.status !== 200) return

      clearInterval(intervalId)

      setSendedFiles(true)

      const msgInfos = _.get(res.data, 'msg_infos')
      if (!_.isEmpty(msgInfos)) {
        toast.error(_.get(msgInfos, '0.msg'))
        // return
      }
      const msgErrors = _.get(res.data, 'msg_errors')
      if (!_.isEmpty(msgErrors)) {
        toast.error(_.get(msgErrors, '0.msg'))
        return
      }

      // ir
      let newIrDataFiles = _
        .chain(res.data)
        .get('ir_response.files')
        .filter({ type: 'declaration' })
        .value()

      let isValueMatched = _
        .chain(res.data)
        .get('ir_response.total_matched')
        .value()

      if (selectedI !== true) newIrDataFiles = addCheckInIrDeclarations(newIrDataFiles)

      const newIrDataFilesReceipt = _
        .chain(res.data)
        .get('ir_response.files')
        .filter({ type: 'receipt' })
        .value()

      setIrDataFilesDeclaration(newIrDataFiles)
      setIrDataFilesReceipt(newIrDataFilesReceipt)
      setIrValidTotal(_.get(res.data, 'ir_response.valid_total'))
      setIrEquityEvolution(_.get(res.data, 'ir_response.equity_evolution'))
      setValueMatched(isValueMatched)

      // dcbe
      let newDcbeDataFiles = _
        .chain(res.data)
        .get('dcbe_response.files')
        .filter({ type: 'declaration' })
        .value()

      if (selectedI !== true) newDcbeDataFiles = addCheckInDcbeAssets(newDcbeDataFiles)

      const newDcbeDataFilesReceipt = _
        .chain(res.data)
        .get('dcbe_response.files')
        .filter({ type: 'receipt' })
        .value()

      console.log(_.get(res.data, 'dcbe_response'))

      setDcbeDataFilesDeclaration(newDcbeDataFiles)
      setDcbeDataFilesReceipt(newDcbeDataFilesReceipt)
      setDcbeValidTotal(_.get(res.data, 'dcbe_response.valid_total'))
      setDcbeGroupedValues(_.get(res.data, 'dcbe_response.grouped_values'))
      setDcbeResponse(_.get(res.data, 'dcbe_response'))

      setLoading(false)
    }, 5000)
  }

  const irExportCsvData = (dataFiles, validTotal) => {
    const assetsField = 'assets_declaration'
    const filesType = FILE_TYPE.IR

    const assets = _
    .chain(dataFiles)
    .filter(x => _.isArrayLikeObject(x[assetsField]))
    .map(x => x[assetsField])
    .flatten()
    .value()

    if (_.isEmpty(assets)) return {}

    return {
      ir_response: {
        total_value: calculeTotal(filesType, dataFiles),
        total_liquid_value: calculeTotal(filesType, dataFiles, { filterChecked: true }),
        total_liquid_value_80_percent: calculeTotal(filesType, dataFiles, { filterChecked: true, percentage: 80 }),
        valid_total: validTotal,
        [assetsField]: assets,
      },
    }
  }

  const dcbeExportCsvData = (dataFiles, validTotal) => {
    const assetsField = 'asset_details'
    const filesType = FILE_TYPE.DCBE

    const assets = _
    .chain(dataFiles)
    .filter(x => _.isArrayLikeObject(x[assetsField]))
    .map(x => x[assetsField])
    .flatten()
    .value()

    if (_.isEmpty(assets)) return {}

    return {
      dcbe_response: {
        total_value: calculeTotal(filesType, dataFiles),
        total_liquid_value: calculeTotal(filesType, dataFiles, { filterChecked: true }),
        total_liquid_value_100_percent: calculeTotal(filesType, dataFiles, { filterChecked: true, percentage: 100 }),
        valid_total: validTotal,
        [assetsField]: assets,
      },
    }
  }

  const handleExportCsv = async (token) => {
    let res = null

    try {
      res = await postGenerateCsv(
        apiKey,
        userId,
        {
          token,
        },
      )
    } catch (err) {
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro exportar CSV'
      toast.error(messageError)
      return
    }

    let filename = void 0;
    if (irDataFilesReceipt[0]) {
      filename = `ir-${irDataFilesReceipt[0].receipt.cpf.replace(/\.|\-/g, '')}-${irDataFilesReceipt[0].receipt.calendar_year}.csv`
    } else {
      filename = `dcbe-${dcbeDataFilesReceipt[0].receipt.cpf.replace(/\.|\-/g, '')}-${dcbeDataFilesReceipt[0].receipt.year_base}.csv`
    }

    const blobText = new Blob([res.data], { type: 'text/csv;charset=utf-8' })
    saveAs(blobText, filename)
  }

  const handleExportPdf = async (token) => {
    let res = null

    try {
      res = await postGeneratePdf(
        apiKey,
        userId,
        {
          token,
        },
      )
    } catch (err) {
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro exportar CSV'
      toast.error(messageError)
      return
    }

    let filename = void 0;
    if (irDataFilesReceipt[0]) {
      filename = `ir-${irDataFilesReceipt[0].receipt.cpf.replace(/\.|\-/g, '')}-${irDataFilesReceipt[0].receipt.calendar_year}.pdf`
    } else {
      filename = `dcbe-${dcbeDataFilesReceipt[0].receipt.cpf.replace(/\.|\-/g, '')}-${dcbeDataFilesReceipt[0].receipt.year_base}.pdf`
    }

    const blobText = new Blob([res.data], { type: 'application/pdf;charset=utf-8' })
    saveAs(blobText, filename)
  }

  const sendFile = async (fileContent, position, filesQuantity, token = null) => {
    const res = await postFile(
      apiKey,
      userId,
      {
        token: token,
        name: fileContent.name,
        base64: fileContent.base64,
        fileType: fileContent.fileType,
        filePosition: position,
        totalRequests: filesQuantity,
      },
    )

    setToken(res.data.token)
    return res.data.token
  }

  const handleSubmit = async (files) => {
    if (_.isEmpty(files)) return

    setLoading(true)

    const filesTreated = _.map(files, (file) => {
      return {
        ...file,
        base64: file.base64.substr(file.base64.indexOf(',') + 1),
      }
    })

    const filesLength = filesTreated.length

    try {
      tokenDocument.current = await sendFile(filesTreated[0], 1, filesLength)
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar arquivos'
      toast.error(messageError)
      return
    }

    try {
      const promises = _.map(filesTreated.slice(1), (fileTreated, i) => {
        return sendFile(fileTreated, i + 2, filesLength, tokenDocument.current)
      })
      await Promise.all(promises)
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar arquivos'
      toast.error(messageError)
      return
    }

    getFilesResult(tokenDocument.current)
  }

  const handleIrDeclarationCheck = (event, fileIndex, declarationIndex) => {
    let newDataFiles = _.cloneDeep(irDataFilesDeclaration)
    let newDataFilesFiltered = _.filter(newDataFiles, 'assets_declaration')

    _.set(
      newDataFilesFiltered,
      `${fileIndex}.assets_declaration.${declarationIndex}.checked`,
      event.target.checked,
    )

    irAssets.push({"id": newDataFilesFiltered[fileIndex].assets_declaration[declarationIndex].id, "checked": newDataFilesFiltered[fileIndex].assets_declaration[declarationIndex].checked})
    setIrAssets(irAssets)

    setIrDataFilesDeclaration(newDataFiles)
  }

  const handleDcbeDeclarationCheck = (event, fileIndex, declarationIndex) => {
    const newDataFiles = _.cloneDeep(dcbeDataFilesDeclaration)
    let newDataFilesFiltered = _.filter(newDataFiles, 'assets_details')

    _.set(
      newDataFilesFiltered,
      `[0].assets_details.${declarationIndex}.checked`,
      event.target.checked,
    )

    dbceAssets.push({"id": newDataFilesFiltered[0].assets_details[declarationIndex].id, "checked": newDataFilesFiltered[0].assets_details[declarationIndex].checked})
    setDbceAssets(dbceAssets)

    setDcbeDataFilesDeclaration(newDataFiles)
  }

  const handleFinalizeOperation = async () => {
    const content = {
      ir_assets: irAssets,
      dcbe_assets: dbceAssets
    }

    try {
      await postConclude(
        apiKey,
        userId,
        tokenDocument.current,
        content,
      )
    } catch (err) {
      Sentry.captureException(err)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao finalizar operação'
      toast.error(messageError)
      return
    }

    resetStates()
    setLastSendDate((new Date()).toString())
  }

  const handleSelectItem = (token) => {
    const selected = true
    resetStates()
    setSelectedItem(selected)
    setToken(token)
    getFilesResult(token, selected)
  }

  const renderFileTypeIr = () => {
    return (
      <React.Fragment>
        <IrReceiptValues
          filesData={irDataFilesReceipt}
        />

        <IrValues
          filesData={irDataFilesDeclaration}
          irDataFilesReceipt={irDataFilesReceipt}
          valueMatched={valueMatched}
          validTotal={irValidTotal}
          onDeclarationCheck={handleIrDeclarationCheck}
          equityEvolution={irEquityEvolution}
          selectedItem={selectedItem}
        />
      </React.Fragment>
    )
  }

  const renderFileTypeDcbe = () => {
    return (
      <React.Fragment>
        <DcbeReceiptValues
          filesData={dcbeDataFilesReceipt}
        />

        <DcbeValues
          filesData={dcbeDataFilesDeclaration}
          validTotal={dcbeValidTotal}
          response={dcbeResponse}
          onDeclarationCheck={handleDcbeDeclarationCheck}
          groupedValues={dcbeGroupedValues}
          selectedItem={selectedItem}
        />
      </React.Fragment>
    )
  }

  return (
    <Container>
      <div>
        <ListConsults onSelectItem={handleSelectItem} lastUpdatedDate={lastSendDate} />
      </div>

      <div className="files-container">
        <div className="files-btns-container">
          <div className="btn-input-file-container">
            <div className="btn-item">
              <span className="btn-item-text">Arquivos IR:</span>
              <Input
                inputRef={ref => { irInputFile.current = ref }}
                accept="image/jfif,image/png,image/jpeg,image/jpg,application/pdf"
                disabled={loading || sendedFiles}
                inputProps={{ multiple: true }}
                onChange={(e) => handleFile(e, FILE_TYPE.IR)}
                type="file"
              />
            </div>

            <div className="btn-item">
              <span className="btn-item-text">Arquivos DCBE:</span>
              <Input
                inputRef={ref => { dcbeInputFile.current = ref }}
                accept="image/jfif,image/png,image/jpeg,image/jpg,application/pdf"
                disabled={loading || sendedFiles}
                inputProps={{ multiple: true }}
                onChange={(e) => handleFile(e, FILE_TYPE.DCBE)}
                type="file"
              />
            </div>
          </div>

          <div className="btn-item">
            <Button
              color="primary"
              disabled={loading || sendedFiles || _.isEmpty(imgsBase64)}
              onClick={() => handleSubmit(imgsBase64)}
              variant="contained"
            >
              Enviar
            </Button>
          </div>
        </div>

        <div className="files-files-container">
          <React.Fragment>
            {_.map(imgsBase64, (fileData, i) => (
              <div key={i.toString()}>
                <div className="img-btn-delete-container">
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => handleFileDelete(i)}
                  >
                    <DeleteOutlinedIcon />
                  </Fab>
                </div>
                {
                  getFileNameExtension(fileData.name) === 'pdf' ? (
                    <Document
                      file={fileData.base64}
                      onLoadSuccess={(data) => handleDocumentPdfLoadSuccess(data, i)}
                      onLoadError={handleDocumentPdfLoadError}
                    >
                      {_.map(new Array(fileData.pdfNumPages), (_val, indexPage) => (
                        <Page pageNumber={indexPage + 1} key={indexPage.toString()} className="PDF-Page" />
                      ))}
                    </Document>
                  ) : <img src={fileData.base64} alt="imagem" width="100%" />
                }
              </div>
            ))}
          </React.Fragment>
        </div>
      </div>

      <div className="content-container">
        <div className="content-btns-container">
          <Button
            disabled={!sendedFiles}
            onClick={() => handleExportCsv(getToken)}
            variant="contained"
          >
            Exportar para CSV
          </Button>

          <Button
            disabled={!sendedFiles}
            onClick={() => handleExportPdf(getToken)}
            variant="contained"
          >
            Exportar para PDF
          </Button>

          {selectedItem ? (
            <Button
              color="primary"
              disabled={loading}
              onClick={() => resetStates()}
              variant="contained"
            >
              Limpar!
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!sendedFiles}
              onClick={() => handleFinalizeOperation()}
              variant="contained"
            >
              Finalizar Operação!
            </Button>
          )}
        </div>

        <div className="values-container">
          {
            loading
            ? <CircularProgress />
            : sendedFiles && (
              <React.Fragment>
                <h2 style={{ alignSelf: 'flex-start', marginBottom: 10, marginTop: 30 }}>
                  Tipo: IR
                </h2>
                {renderFileTypeIr()}

                <h2 style={{ alignSelf: 'flex-start', marginBottom: 10, marginTop: 30 }}>
                  Tipo: DCBE
                </h2>
                {renderFileTypeDcbe()}
              </React.Fragment>
            )
          }
        </div>
      </div>
    </Container>
  )
}

export default Home
