import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Recaptcha from 'react-recaptcha'
import { TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { successLogin, failChangePassword } from 'redux/auth/actions'

import { changePassword } from 'services/apiAuth'
import {
  isNetworkError,
  isPasswordValid,
  isUserLogged,
  sleep,
} from 'utils/functions'

import styles from './styles'

const PasswordChange = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const email = useSelector(state => state.auth.email)
  const changePasswordSession = useSelector(state => state.auth.changePasswordSession)
  const accessToken = useSelector(state => state.auth.access_token)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  useEffect(() => {
    if (changePasswordSession === null) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUserLogged(accessToken)) {
      history.push('/home')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const handleSubmit = async () => {
    setLoading(true)

    try {
      const data = {
        app_id: process.env.REACT_APP_COGNITO_APP_ID,
        user_pool: process.env.REACT_APP_COGNITO_USER_POOL,
        username_email: email,
        password: password,
        user_attributes: {
          name: name,
        },
        session: changePasswordSession,
        recaptcha_token: recaptchaToken,
      }

      const res = await changePassword(data)

      if (res) {
        const userdata = res.data
        // waits till create user data
        await sleep(5000)
        dispatch(successLogin(userdata))
        setLoading(false)
      }
    } catch (err) {
      dispatch(failChangePassword())

      setLoading(false)
      setError(true)
      setIsVerified(false)
      setRecaptchaToken(null)

      if (_.get(err, 'response.status') === 400) {
        toast.error('Sua sessão expirou. Faça o login novamente!')
        history.push('/')
        return
      }

      const status = _.get(err, 'response.status') || err.status
      const statusMsg = status ? `${status}: ` : ''
      const messageError = isNetworkError(err)
        ? `${statusMsg}Problema na solicitação: ${err.message}`
        : `${statusMsg}${_.get(err, 'response.data.msg_errors.0.msg')}`

      toast.error(messageError)
    }
  }

  const verifyCaptcha = response => {
    if (response) {
      setIsVerified(true)
      setRecaptchaToken(response)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="assets/images/logos/inova_white.svg" alt="Logo" className={classes.logo} />
        <div className={classes.titleSidebarContainer}>
          <h1 className={classes.titleSidebar}>Redefinir Senha</h1>
          <p className={classes.subtitleSidebar}>Redefina sua senha para acessar a plataforma!</p>
        </div>
      </div>
      <div className={classes.main}>
        <h1 className={classes.title}>Redefinir Senha</h1>

        <TextField
          id="name"
          label="Nome"
          className={classes.textField}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!(name.length !== 0 && name.length < 2)}
          type="text"
          required={true}
          autoCorrect="off"
          autoCapitalize="off"
        />

        <TextField
          id="password"
          autoComplete="new-password"
          label="Senha"
          className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          required={true}
          autoCorrect="off"
          autoCapitalize="off"
          error={password.length !== 0 ? !isPasswordValid(password) : false}
          helperText={
            password.length !== 0
              ? isPasswordValid(password)
                ? false
                : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
              : false
          }
        />

        <TextField
          id="confirmPassword"
          autoComplete="off"
          label="Confirmar Senha"
          className={classes.textField}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          required={true}
          autoCorrect="off"
          autoCapitalize="off"
          error={!!(confirmPassword.length !== 0 && confirmPassword !== password)}
          helperText={confirmPassword !== password ? 'As senhas precisam ser iguais.' : null}
        />
        {error ? (
          <p>
            <strong className={classes.error}>
              Erro ao redefinir a senha, verifique seus dados e tente novamente.
            </strong>
          </p>
        ) : null}

        <div className={classes.buttonContainer}>
          <div className={isVerified ? classes.noDisplay : ''}>
            <Recaptcha
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              render="explicit"
              expiredCallback={() => {}}
              onloadCallback={() => {}}
              verifyCallback={verifyCaptcha}
              hl="pt-BR"
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classnames(classes.button, !isVerified ? classes.noDisplay : '')}
            onClick={handleSubmit}
            disabled={!!loading}
          >
            {
              loading
              ? <CircularProgress className={classes.progress} />
              : 'Confirmar'
            }
          </Button>
          <Typography>
            Entrar com outra conta.
            {' '}
            <Link to="/">
              Fazer Login
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(PasswordChange)
